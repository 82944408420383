'use client'
import { useId, useState, useEffect } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

import { AppDemo } from '@/components/AppDemo'
import { AppStoreLink } from '@/components/AppStoreLink'
import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { PhoneFrame } from '@/components/PhoneFrame'
import { animate } from 'motion'
import logoBbc from '@/images/logos/bbc.svg'
import logoCbs from '@/images/logos/cbs.svg'
import logoCnn from '@/images/logos/cnn.svg'
import logoFastCompany from '@/images/logos/fast-company.svg'
import logoForbes from '@/images/logos/forbes.svg'
import logoHuffpost from '@/images/logos/huffpost.svg'
import logoTechcrunch from '@/images/logos/techcrunch.svg'
import logoWired from '@/images/logos/wired.svg'
import TogglingHeading from './TogglingHeading'
import { Carousel2 } from './Carousel2'
import { TabList } from './Tablist'
import { signIn, signOut, useSession } from 'next-auth/react'
import { useQuery, gql } from '@apollo/client'
import ClientOnlyWrapper from './ClientOnlyWrapper'
import GQLExample from './GQLExample'

function BackgroundIllustration(props: React.ComponentPropsWithoutRef<'div'>) {
  let id = useId()

  return (
    <div {...props}>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-slow"
      >
        <path
          d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
          stroke="#FFFFFF"
          strokeOpacity="0.7"
        />
        <path d="M513 1025C230.23 1025 1 795.77 1 513" stroke={`url(#${id}-gradient-1)`} strokeLinecap="round" />
        <defs>
          <linearGradient id={`${id}-gradient-1`} x1="1" y1="513" x2="1" y2="1025" gradientUnits="userSpaceOnUse">
            <stop stopColor="#06b6d4" />
            <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
      >
        <path
          d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path d="M913 513c0 220.914-179.086 400-400 400" stroke={`url(#${id}-gradient-2)`} strokeLinecap="round" />
        <defs>
          <linearGradient id={`${id}-gradient-2`} x1="913" y1="513" x2="913" y2="913" gradientUnits="userSpaceOnUse">
            <stop stopColor="#06b6d4" />
            <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

function PlayIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <circle cx="12" cy="12" r="11.5" stroke="#D4D4D4" />
      <path
        d="M9.5 14.382V9.618a.5.5 0 0 1 .724-.447l4.764 2.382a.5.5 0 0 1 0 .894l-4.764 2.382a.5.5 0 0 1-.724-.447Z"
        fill="#A3A3A3"
        stroke="#A3A3A3"
      />
    </svg>
  )
}

const tabs = ['electrician', 'gas engineer', 'plumber', 'tiler', 'floorer', 'others']

export function Hero() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [activeTab, setActiveTab] = useState(tabs[0])
  const { data: session, status } = useSession()
  console.log(session)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevActiveTab) => {
        const currentIndex = tabs.indexOf(prevActiveTab)

        const nextIndex = currentIndex >= tabs.length - 1 ? 0 : currentIndex + 1

        return tabs[nextIndex]
      })
    }, 4000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36">
      <Container>
        {/* <ClientOnlyWrapper>
          <GQLExample />
        </ClientOnlyWrapper> */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
          <div className="relative z-10 max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
            <div className="text-4xl font-bold tracking-tight text-blue-800 transition ease-in-out">
              <div>Repairs and upkeep</div>
              <div>done with ease.</div>
            </div>

            <div className="">
              <p className="mt-6 text-lg text-gray-600">
                <strong>Identify issues, </strong>
                <span className="font-bold text-blue-800">check prices</span> &<strong> compare contractors</strong>{' '}
                before you book. All online.
              </p>
            </div>

            <div className="mt-8 flex flex-wrap gap-x-6 gap-y-4">
              <Button href="/quote/start" variant="solid" color="blue">
                <span className="ml">Get a price</span>
              </Button>
              {/* <Button onClick={() => setIsModalOpen(true)} variant="outline" color="gray">
                <PlayIcon className="h-6 w-6 flex-none" />
                <span className="ml-2.5">See how we work</span>
              </Button> */}
            </div>
            {isModalOpen && (
              <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-70">
                <div className="relative rounded-lg bg-white p-6">
                  <button className="absolute right-2 top-2" onClick={() => setIsModalOpen(false)}>
                    &times;
                  </button>

                  <iframe
                    className="aspect-video w-full"
                    src="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )}
          </div>
          <div className="relative z-0 mt-10 sm:mt-20 sm:hidden md:block lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
            <BackgroundIllustration className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0" />
            <div className="object-scale-down px-4 pb-4 sm:mx-0 sm:px-9 lg:absolute lg:-inset-x-2 lg:-bottom-10 lg:-top-5 lg:h-auto lg:px-0 lg:pt-5 xl:-bottom-16">
              <Carousel2 activeTab={activeTab} />
            </div>
          </div>
          <div className="relative mt-16 lg:col-span-7 lg:mt-0 xl:col-span-6">
            <p className="text-center text-sm font-semibold uppercase text-blue-800 lg:text-left">
              Contractors Available
            </p>
            <ul
              role="list"
              className="mx-auto mt-4 flex max-w-xl flex-wrap justify-center gap-x-10 gap-y-8 lg:mx-0 lg:justify-start"
            >
              <TabList activeTab={activeTab} setActiveTab={setActiveTab} />
            </ul>
          </div>
        </div>
      </Container>
    </div>
  )
}
