import React from 'react'
import { Button } from './Button'

type TabListProps = {
  activeTab: string
  setActiveTab: (value: string) => void
}

export function TabList({ activeTab, setActiveTab }: TabListProps) {
  return (
    <div className="justify-left flex">
      <div className="flex max-w-3xl">
        <div className="justify-left relative flex max-w-5xl flex-wrap items-start lg:-mx-2 lg:items-end">
          <Button
            variant="outline"
            color="blue"
            className={`ml-2 mt-8 ${activeTab === 'electrician' ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
            role="button"
            onClick={() => setActiveTab('electrician')}
          >
            Electricians
            <div
              className="absolute bottom-[20%] left-0 top-[20%] h-[60%] w-[4px] transition duration-300 ease-in-out lg:bottom-0 lg:left-[20%] lg:right-[20%] lg:top-auto lg:h-[4px] lg:w-auto"
              style={{ opacity: activeTab === 'electrician' ? 1 : 0 }}
            ></div>
          </Button>
          <Button
            variant="outline"
            color="blue"
            className={`ml-2 mt-8 ${activeTab === 'gas engineer' ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
            role="button"
            onClick={() => setActiveTab('gas engineer')}
          >
            Gas Engineers
            <div
              className="absolute bottom-[20%] left-0 top-[20%] h-[60%] w-[4px] transition duration-300 ease-in-out lg:bottom-0 lg:left-[20%] lg:right-[20%] lg:top-auto lg:h-[4px] lg:w-auto"
              style={{ opacity: activeTab === 'gas engineer' ? 1 : 0 }}
            ></div>
          </Button>
          <Button
            variant="outline"
            color="blue"
            className={`ml-2 mt-8 ${activeTab === 'plumber' ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
            role="button"
            onClick={() => setActiveTab('plumber')}
          >
            Plumbers
            <div
              className="absolute bottom-[20%] left-0 top-[20%] h-[60%] w-[4px] transition duration-300 ease-in-out lg:bottom-0 lg:left-[20%] lg:right-[20%] lg:top-auto lg:h-[4px] lg:w-auto"
              style={{ opacity: activeTab === 'plumber' ? 1 : 0 }}
            ></div>
          </Button>

          <Button
            variant="outline"
            color="blue"
            className={`ml-2 mt-8 ${activeTab === 'tiler' ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
            role="button"
            onClick={() => setActiveTab('tiler')}
          >
            Tilers
            <div
              className="absolute bottom-[20%] left-0 top-[20%] h-[60%] w-[4px] transition duration-300 ease-in-out lg:bottom-0 lg:left-[20%] lg:right-[20%] lg:top-auto lg:h-[4px] lg:w-auto"
              style={{ opacity: activeTab === 'tiler' ? 1 : 0 }}
            ></div>
          </Button>
          <Button
            variant="outline"
            color="blue"
            className={`ml-2 mt-8 ${activeTab === 'floorer' ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
            role="button"
            onClick={() => setActiveTab('floorer')}
          >
            Floorers
            <div
              className="absolute bottom-[20%] left-0 top-[20%] h-[60%] w-[4px] transition duration-300 ease-in-out lg:bottom-0 lg:left-[20%] lg:right-[20%] lg:top-auto lg:h-[4px] lg:w-auto"
              style={{ opacity: activeTab === 'floorer' ? 1 : 0 }}
            ></div>
          </Button>
          <Button
            variant="outline"
            color="blue"
            className={`ml-2 mt-8 ${activeTab === 'others' ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
            role="button"
            onClick={() => setActiveTab('others')}
          >
            and many others
            <div
              className="absolute bottom-[20%] left-0 top-[20%] h-[60%] w-[4px] transition duration-300 ease-in-out lg:bottom-0 lg:left-[20%] lg:right-[20%] lg:top-auto lg:h-[4px] lg:w-auto"
              style={{ opacity: activeTab === 'others' ? 1 : 0 }}
            ></div>
          </Button>
        </div>
      </div>
    </div>
  )
}
