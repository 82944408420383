'use client'
import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { Container } from '@/components/Container'
import { Button } from './Button'

type Carousel2Props = {
  activeTab: string
}
export function Carousel2({ activeTab }: Carousel2Props) {
  return (
    <div className="py-30 z-auto min-h-fit w-5/6 pt-8">
      <div
        className={
          activeTab === 'electrician'
            ? ''
            : activeTab === 'gas engineer'
              ? ''
              : activeTab === 'plumber'
                ? ''
                : activeTab === 'tiler'
                  ? ''
                  : activeTab === 'floorer'
                    ? ''
                    : activeTab === 'others'
                      ? ''
                      : '' + ' mt-[1rem] rounded-lg transition lg:mt-[2rem] lg:p-[4rem]'
        }
      >
        <div className={activeTab === 'electrician' ? '' : 'hidden'}>
          <Image
            src="/image/hero/new/montage_electrician.png"
            height={667}
            width={500}
            alt="electrician"
            className="relative z-10 mx-auto max-w-2xl rounded-xl object-scale-down transition duration-300 lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6"
          />
        </div>

        <div className={activeTab === 'gas engineer' ? '' : 'hidden'}>
          <Image
            src="/image/hero/new/montage_gasengineer.png"
            height={667}
            width={500}
            alt="gas engineer"
            className="relative z-10 mx-auto max-w-2xl rounded-xl object-scale-down transition duration-300 ease-in-out lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6"
          />
        </div>

        <div className={activeTab === 'plumber' ? '' : 'hidden'}>
          <Image
            src="/image/hero/new/montage_plumber.png"
            height={667}
            width={500}
            alt="Plumber"
            className="relative z-10 mx-auto max-w-2xl rounded-xl object-scale-down transition duration-300 ease-in-out lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6"
          />
        </div>
        <div className={activeTab === 'tiler' ? '' : 'hidden'}>
          <Image
            src="/image/hero/new/montage_tiler.png"
            height={667}
            width={500}
            alt="tiler"
            className="relative z-10 mx-auto max-w-2xl rounded-xl object-scale-down transition duration-300 ease-in-out lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6"
          />
        </div>
        <div className={activeTab === 'floorer' ? '' : 'hidden'}>
          <Image
            src="/image/hero/new/montage_floorer.png"
            height={667}
            width={500}
            alt="floorer"
            className="relative z-10 mx-auto max-w-2xl rounded-xl object-scale-down transition duration-300 ease-in-out lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6"
          />
        </div>
        <div className={activeTab === 'others' ? '' : 'hidden'}>
          <Image
            src="/image/hero/new/montage_others.png"
            height={667}
            width={500}
            alt="Others"
            className="relative z-10 mx-auto max-w-2xl rounded-xl object-scale-down transition duration-300 ease-in-out lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6"
          />
        </div>
      </div>
    </div>
  )
}
