import Link from 'next/link'
import clsx from 'clsx'
import { ClassValue } from 'clsx'

type VariantKey = keyof typeof variantStyles
type ColorKey<Variant extends VariantKey> = keyof (typeof variantStyles)[Variant]

type ButtonProps<Variant extends VariantKey, Color extends ColorKey<Variant>> = {
  variant?: Variant
  color?: Color
} & (
  | Omit<React.ComponentPropsWithoutRef<typeof Link>, 'color'>
  | (Omit<React.ComponentPropsWithoutRef<'button'>, 'color'> & {
      href?: undefined
    })
)

const baseStyles: Record<string, string> = {
  solid:
    'inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors',
  outline:
    'inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors',
}

const variantStyles = {
  solid: {
    blue: 'relative overflow-hidden bg-blue-600 text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-blue-500 active:text-white/80 before:transition-colors',
    cyan: 'relative overflow-hidden bg-blue-800 text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-blue-600 active:text-white/80 before:transition-colors',
    white: 'bg-white text-cyan-900 hover:bg-white/90 active:bg-white/90 active:text-cyan-900/70',
    gray: 'bg-gray-500 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white',
  },
  outline: {
    gray: 'border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80',
    blue: 'border-blue-300 text-blue-600 hover:border-blue-400 active:bg-blue-100 active:text-blue-500/80',
  },
  sansoutline: {
    gray: 'text-gray-500 hover:text-gray-700 active:text-gray-700 active:text-gray-700/80',
  },
}

export function Button<Color extends ColorKey<Variant>, Variant extends VariantKey = 'solid'>({
  variant,
  color,
  className,
  ...props
}: ButtonProps<Variant, Color>) {
  variant = variant ?? ('solid' as Variant)
  color = color ?? ('blue' as Color)

  const idx = String(variant)
  className = clsx(baseStyles[variant], variantStyles[variant][color] as ClassValue[], className)

  return typeof props.href === 'undefined' ? (
    <button className={className} {...props} />
  ) : (
    <Link className={className} {...props} />
  )
}
